updated.....

<template>
  <sb-page-content title="Create Vehicle"
                   class="back-button-margin">
    <sb-single-vehicle-capture-dialog v-if="showVehicleCaptureDialog"
                                      :client-id="customerInfomation.fleetAccountNumber"
                                      :show-dialog="showVehicleCaptureDialog"
                                      :vehicle-entity="vehicleEntity"
                                      @close="onModalClose"
                                      @vehicle-item="addVehicleItem" />
    <sb-card :title="currentTitle">
      <v-stepper v-model="step"
                 alt-labels
                 class="elevation-0">
        <v-stepper-header>
          <template v-for="n in 4">
            <v-stepper-step :key="`${n}-step`"
                            :complete="step > n"
                            :step="n">
              <span class="text-stepper">{{ stepTitles[n - 1] }}</span>
            </v-stepper-step>

            <v-divider v-if="n < 4"
                       :key="`${n}-divider`" />
          </template>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content v-for="n in 4"
                             :key="`${n}-content`"
                             :step="n" />
        </v-stepper-items>
      </v-stepper>

      <ValidationObserver ref="obs">
        <div class="sb-create-sr-card">
          <v-window v-model="step">
            <v-window-item :value="1">
              <v-row v-if="vehicleCaptureRequestNumber">
                <v-col cols="4"
                       sm="4">
                  <sb-text-field v-model="vehicleCapture.vehicleCapture.customerInfomation.fleetAccountNumber"
                                 disabled
                                 label="Client Number" />
                </v-col>
                <v-col cols="4"
                       sm="4">
                  <sb-text-field v-model="vehicleCapture.vehicleCapture.customerInfomation.clientName"
                                 disabled
                                 label="Client Name" />
                </v-col>
              </v-row>

              <v-row v-else>
                <v-card-text class="py-0">
                  <v-form>
                    <sb-single-vehicle-capture-customer-information :customer-infomation-entity.sync="customerInfomation"
                                                                    @client-selected="checkClientSelected" />
                  </v-form>
                </v-card-text>
              </v-row>
            </v-window-item>
            <v-window-item :value="2">
              <v-card-text class="py-0">
                <v-row v-if="!vehicleCaptureRequestNumber">
                  <v-col cols="12"
                         sm="6">
                    <sb-client-vehicle-search :vehicle-default-value="templateVehicleNumber"
                                              :client-id="customerInfomation.fleetAccountNumber"
                                              ref-name="vehicleSearch"
                                              label="Use As Template Vehicle Number"
                                              @vehicle="onSelectedVehicle" />
                  </v-col>
                </v-row>

                <v-row v-if="!vehicleCaptureRequestNumber">
                  <v-col cols="12"
                         sm="8">
                    <sb-input label="Manual capture or template vehicle add?" />

                    <v-radio-group v-model="selectedTypeOfCapture"
                                   row
                                   @change="onManualCaptureOrTemplate">
                      <v-radio v-for="captureType in captureTypes"
                               :key="captureType.key"
                               :label="captureType.value"
                               :value="captureType.value"
                               on-icon="mdi-radiobox-marked" />
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row v-if="selectedTypeOfCapture === 'Template'">
                  <v-col cols="12"
                         sm="4">
                    <sb-file-upload-field v-model="template"
                                          placeholder="Upload"
                                          :file-types="'.xlsx'"
                                          label="Upload" />
                  </v-col>
                  <v-col cols="auto">
                    <v-btn x-large
                           color="secondary"
                           :disabled="!canUpload"
                           @click="onTemplateUpload">
                      Upload
                      <v-icon right
                              dark>
                        mdi-cloud-upload
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-window-item>
            <v-window-item :value="3">
              <v-card-text class="py-0">
                <v-form>
                  <v-row justify="center">
                    <v-col cols="12"
                           md="12">
                      <v-card-text class="py-0">
                        <v-row align="start">
                          <v-col cols="12"
                                 class="text-right">
                            <v-btn text
                                   large
                                   color="primary"
                                   @click="showVehicleCaptureDialog=true;">
                              <v-icon>mdi-plus-circle-outline </v-icon>
                              Add a vehicle
                            </v-btn>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12"
                                 md="12">
                            <sb-data-table :headers="BULK_SINGLE_VEHICLE_UPLOAD_HEADERS['vehicleInfo']"
                                           :items="vehicleCapture.vehicleCapture.vehicles"
                                           default-pagination
                                           empty-message="No Vehicle Infomation"
                                           empty-sub-message="No Vehicle Infomation at this time"
                                           show-actions
                                           :show-errors="true"
                                           :show-erros-as-list="true"
                                           @selected-item="selectedItem" />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-window-item>
            <v-window-item :value="4">
              <v-card-text class="py-0">
                <v-form>
                  <v-row justify="center">
                    <v-col cols="12"
                           md="12">
                      <sb-checkbox v-model="confirmation"
                                   label="Do you confirm that the information supplied is correct and complete?"
                                   @click="canSubmit" />
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-window-item>
          </v-window>
        </div>
        <div class="text-center">
          <v-dialog v-model="showPendingErrorsMessage"
                    width="300">
            <v-card>
              <v-card-text>
                Pending Validation Errors
              </v-card-text>

              <v-card-actions>
                <v-btn color="primary"
                       block
                       @click="hideShowPendingErrorsMessage">
                  Close Dialog
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <v-card-actions>
          <v-btn :disabled="step === 1"
                 outlined
                 large
                 color="secondary"
                 @click="step--">
            Back
          </v-btn>
          <v-spacer />
          <v-btn v-if="step ===2"
                 color="secondary"
                 depressed
                 :disabled="manualTypeSelected"
                 large
                 @click="step++">
            Next
          </v-btn>
          <v-btn v-if="step === 1"
                 color="secondary"
                 depressed
                 large
                 :disabled="clientNotselected"
                 @click="step++">
            Next
          </v-btn>
          <v-btn v-if="step ===3"
                 color="secondary"
                 depressed
                 :disabled="vehicleCapture.vehicleCapture.vehicles.length === 0"
                 large
                 @click="step++">
            Next
          </v-btn>

          <v-btn v-if="step === 4"
                 :disabled="vehicleCapture.vehicleCapture.vehicles.length === 0"
                 color="secondary"
                 depressed
                 large
                 @click="saveRequest">
            Save
          </v-btn>
          <v-btn v-if="step === 4"
                 :disabled="canSubmitRequest"
                 color="success"
                 depressed
                 large
                 @click="submitRequest">
            Create Request
          </v-btn>
        </v-card-actions>
      </validationobserver>
    </sb-card>
  </sb-page-content>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import SbSingleVehicleCaptureDialog from '@/components/vehicleCapture/SbSingleVehicleCaptureDialog';
import { BULK_SINGLE_VEHICLE_UPLOAD_HEADERS, CUSTOMER_CAPTURE_TYPES, SR_STATUSES, SR_APPLICATION_TYPES } from '@/config/constants';
import SbSingleVehicleCaptureCustomerInformation from '@/components/vehicleCapture/SbSingleVehicleCaptureCustomerInformation';
import { mapActions } from 'vuex';
import SbDataTable from '@/components/core/SbDataTable';
import moment from 'moment';
import { mapWaitingActions } from 'vue-wait';
import SbClientVehicleSearch from '@/components/client/SbClientVehicleSearch';
import Audit from '@/models/Audit';
import CustomerInfomation from '../../models/vehicleCapture/CustomerInfomation';
import Vehicle from '../../models/vehicleCapture/Vehicle';
import ProductSetup from '../../models/vehicleCapture/ProductSetup';
import VehicleCapture from '../../models/serviceRequest/VehicleCapture';
import ServiceRequest from '../../models/serviceRequest/ServiceRequest';

export default {
  components: {
    SbSingleVehicleCaptureDialog,
    ValidationObserver,
    SbSingleVehicleCaptureCustomerInformation,
    SbClientVehicleSearch,
    SbDataTable,
  },
  props: {
    vehicleCaptureRequestNumber: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      vehicleEntity: {},
      vehicleInfomationData: [],
      selectedVehicleTemplate: '',

      selectedItemIndex: -1,
      selectedAction: '',
      previousVehicleEntity: {},
      customerInfomation: new CustomerInfomation(),
      vehicleCapture: new VehicleCapture(),
      serviceRequest: new ServiceRequest(),
      step: 1,
      stepTitles: ['Customer Information', 'Request Details', 'Vehicles', 'Confirmation'],
      tab: null,
      BULK_SINGLE_VEHICLE_UPLOAD_HEADERS,
      showVehicleCaptureDialog: false,
      templateVehicleNumber: '',
      selectedTypeOfCapture: '',
      captureTypes: [CUSTOMER_CAPTURE_TYPES.MANUAL, CUSTOMER_CAPTURE_TYPES.TEMPLATE],
      template: [],

      confirmation: false,

      canUpload: false,

      territories: ['', 'Rural', 'Urban'],

      // temporary set to true
      invalid: true,

      clientNotselected: true,
      requestTypeNotSelected: true,
      manualTypeSelected: true,
      canSubmitRequest: true,
      showPendingErrorsMessage: false,
      documentsList: [],

    };
  },
  computed: {
    currentTitle() {
      return this.stepTitles[this.step - 1];
    },
  },

  watch: {
    async template() {
      if (this.template.length > 0) {
        this.vehicleCapture.vehicleCapture.vehicles = await this.bulkVehicleUploadTemplate({
          files: this.template,
        });
        this.canUpload = !this.canUpload;
      } else {
        this.canUpload = false;
      }
    },

    vehicleCaptureRequestNumber: {
      handler(value) {
        this.displayVehicleCapture(value);
      },
      immediate: true,
    },

  },
  methods: {
    ...mapActions('vehicleCapture', ['bulkVehicleUploadTemplate', 'addTransactionItem', 'removeTransationItem', 'editTransactionItem']),

    ...mapActions('serviceRequests', ['createServiceRequest', 'getServiceRequest', 'updateServiceRequestEx', 'updateServiceRequestV']),

    ...mapActions('attachments', ['getAttachment', 'uploadAttachments', 'getAllAttachments']),

    ...mapWaitingActions('serviceRequests', {
      createServiceRequest: 'serviceRequestLoading',
      getServiceRequest: 'getServiceRequestLoading',
      updateServiceRequestEx: 'updateServiceRequestExLoading',
    }),

    hideShowPendingErrorsMessage() {
      this.showPendingErrorsMessage = false;
      this.confirmation = false;
    },

    checkClientSelected(value) {
      if (value === 'selected') {
        this.clientNotselected = false;
      } else {
        this.clientNotselected = true;
      }
    },
    async onSelectedVehicle(value) {
      const payload = {
        vehicleNumber: value.vehicleNumber,
        clientId: this.clientId,
      };
      const results = await this.getVehicleTemplate(payload);
      this.$emit('template', results);
    },

    onModalClose() {
      this.showVehicleCaptureDialog = !this.showVehicleCaptureDialog;
      this.vehicleEntity = {};
    },

    async onRequestTypeChange(value) {
      if (value !== CUSTOMER_CAPTURE_TYPES.MANUAL.value) {
        this.vehicleCapture.vehicleCapture.vehicles = await this.bulkVehicleUploadTemplate({
          files: value,
        });
      }
      this.step += 1;
      this.disabledNextButton = false;
    },

    async submitRequest() {
      if (this.vehicleCapture.serviceRequestNumber) {
        await this.updateVehicleCapture(SR_STATUSES.SR_STATUS_IN_PROGRESS.value);
      } else {
        await this.createVehicleCapture(SR_STATUSES.SR_STATUS_IN_PROGRESS.value);
      }
      this.$router.push({
        name: 'VehicleCaptureViewVehicleCapture',
        params: { vehicleCaptureRequestNumber: this.vehicleCapture.serviceRequestNumber },
      });
    },

    async saveRequest() {
      if (this.vehicleCapture.serviceRequestNumber) {
        await this.updateVehicleCapture(SR_STATUSES.SR_STATUS_SAVED.value);
      } else {
        await this.createVehicleCapture(SR_STATUSES.SR_STATUS_SAVED.value);
      }
      this.$router.push({
        name: 'VehicleCaptureViewVehicleCapture',
        params: { vehicleCaptureRequestNumber: this.vehicleCapture.serviceRequestNumber },
      });
    },

    async selectedItem(selectedItem) {
      const copySelectedItem = JSON.parse(JSON.stringify(selectedItem));

      this.selectedAction = copySelectedItem.selectedAction;

      this.selectedItemIndex = copySelectedItem.selectedIndex;
      const payload = {
        item: copySelectedItem.selectedItem,
        vehicleCapture: this.vehicleCapture.vehicleCapture,
        selectedItemIndex: this.selectedItemIndex,
      };
      await this.handleSelectedItem(copySelectedItem, payload);
    },

    async handleSelectedItem(selectedItem, payload) {
      let vehicles = [];
      if (this.selectedAction === 'Edit') {
        this.showVehicleCaptureDialog = true;
        this.vehicleEntity = { ...selectedItem.selectedItem };
        this.previousVehicleEntity = JSON.parse(JSON.stringify(selectedItem.selectedItem));

        return;
      }

      if (this.selectedAction === 'Remove') {
        vehicles = await this.removeTransationItem(payload);
      }
      this.vehicleCapture.vehicleCapture.vehicles = vehicles;
    },

    async onTemplateUpload() {
      this.vehicleCapture.vehicleCapture.vehicles = await this.bulkVehicleUploadTemplate({
        files: this.template,
      });
      this.step += 1;
      this.disabledNextButton = false;
    },

    onManualCaptureOrTemplate(value) {
      if (value === CUSTOMER_CAPTURE_TYPES.MANUAL.value) {
        this.$emit('onChange', value);
        this.manualTypeSelected = false;
      } else {
        this.manualTypeSelected = true;
      }
    },

    onUseVehicleTemplate(item) {
      const productSetup = new ProductSetup();
      productSetup.transLimit = item[0].TRANS_LIMIT;
      this.vehicle.productSetup = productSetup;
    },
    resetForm() {
      this.dialog = false;
      this.vehicle = new Vehicle();
    },

    async addVehicleItem(item) {
      let vehicles = [];

      this.vehicleCapture.hasErrors = item.hasErrors;
      this.vehicleCapture.vehicleCapture.hasErrors = item.hasErrors;

      const payload = {
        item,
        vehicleCapture: this.vehicleCapture,
        selectedItemIndex: this.selectedItemIndex,
        previousItem: this.previousVehicleEntity,
      };

      if (this.selectedAction === 'Edit') {
        vehicles = await this.editTransactionItem(payload);
        this.editOrAddVehicle(vehicles);
        this.selectedAction = '';
        return;
      }

      vehicles = await this.addTransactionItem(payload);

      this.editOrAddVehicle(vehicles);
      this.resetForm();
    },

    editOrAddVehicle(vehicles) {
      this.vehicleCapture.vehicleCapture.vehicles = vehicles;
    },

    canSubmit() {
      if (this.confirmation === false) {
        if (this.vehicleCapture.hasErrors) {
          // display pop up message
          this.showPendingErrorsMessage = true;
        } else {
          this.canSubmitRequest = false;
        }
      }
    },
    async createVehicleCapture(requestStatus) {
      const currentDate = moment().format('YYYY-MM-DD');
      const currentTime = moment().format('HH:mm:ss');
      this.vehicleCapture.applicationType.key = SR_APPLICATION_TYPES.SR_APPLICATION_TYPE_VEHICLE_CAPTURE;
      this.vehicleCapture.createdTimestamp = `${currentDate} ${currentTime}`;
      this.vehicleCapture.status.key = requestStatus;
      this.vehicleCapture.vehicleCapture.confirmation = this.confirmation;
      // this.vehicleCapture.vehicleCapture.requestType = this.captureType.value;
      this.vehicleCapture.vehicleCapture.customerInfomation = this.customerInfomation;

      const serviceRequestNumber = await this.createServiceRequest(this.vehicleCapture);
      if (serviceRequestNumber) {
        // add success pop up and ok to go home or submitted vehicles

        // check if there is document to be uploaded
        await this.uploadUpdatedDocuments();

        await this.displayVehicleCapture(serviceRequestNumber);
        this.$router.push({
          name: 'VehicleCaptureViewVehicleCapture',
          params: { vehicleCaptureRequestNumber: serviceRequestNumber },
        });
      }
    },

    async uploadUpdatedDocuments() {
    //   // check
    //   console.log('Document upload');
    //   console.log(`Length == ${this.vehicleCapture.vehicleCapture.vehicles.length}`);
    //   console.log(`Length Document == ${this.vehicleCapture.vehicleCapture.vehicles[0].driverInformation.proofOfbanking.length}`);

    //   for (let index = 0; index < this.vehicleCapture.vehicleCapture.vehicles.length; index += 1) {
    //     if (this.vehicleCapture.vehicleCapture.vehicles[index].driverInformation.proofOfbanking.length > 0) {
    //       const payload = {
    //         fileType: '',
    //         files: this.vehicleCapture.vehicleCapture.vehicles[index].driverInformation.proofOfbanking,
    //         serviceRequestId: this.vehicleCapture.serviceRequestNumber,
    //       };
    //       console.log(`Upload Documents${payload}`);
    //       // await this.uploadVehicleCaptureDocuments(payload, this.fileType);
    //     }
    //   }
    },

    async uploadVehicleCaptureDocuments(payload, key) {
      const response = await this.uploadAttachments(payload);
      if (response.length === 0) {
        this.documentsList.push({
          key,
          uploaded: false,
        });
      }
    },

    async updateVehicleCapture(vehicleCaptureStatus) {
      if (vehicleCaptureStatus) {
        this.vehicleCapture.status.key = vehicleCaptureStatus;
      }

      await this.updateVehicleCaptureServiceRequest(this.vehicleCapture);
    },

    async updateVehicleCaptureServiceRequest(payloadVehicleCapture) {
      const payload = {
        serviceRequest: payloadVehicleCapture,
        audit: new Audit(),
      };

      await this.updateServiceRequestV(payload);
    },
    async displayVehicleCapture(value) {
      this.serviceRequest = new ServiceRequest();
      this.vehicleCapture = new VehicleCapture();

      if (value) {
        const request = await this.getServiceRequest(value);
        this.vehicleCapture.id = request.id;
        this.vehicleCapture.vehicleCapture = request.vehicleCapture;
        this.vehicleCapture.serviceRequestNumber = request.serviceRequestNumber;
        this.vehicleCapture.applicationType = request.applicationType;
        this.vehicleCapture.createdTimestamp = request.createdTimestamp;
        this.vehicleCapture.status = request.status;
        this.vehicleCapture.audit = request.audit;
        this.vehicleCapture.closedTimestamp = request.closedTimestamp;

        // customer information initialization
        this.customerInfomation = request.vehicleCapture.customerInfomation;

        this.clientNotselected = false;
        this.manualTypeSelected = false;
      }
    },
  },
};
</script>
