import ServiceRequest from './ServiceRequest';
import VehicleCaptureDetails from '../vehicleCapture/VehicleCaptureDetails';

export default class VehicleCapture extends ServiceRequest {
  constructor(existingObject) {
    super(existingObject);

    const object = existingObject || [];
    this.vehicleCapture = new VehicleCaptureDetails(object.vehicleCapture);
  }
}
